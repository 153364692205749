import React from "react";

function Alert(props) {
  const capitalize = (word) => {
    if (word === "danger") {
      word = "error";
    }
    const lower = word.toLowerCase();
    return lower.charAt(0).toUpperCase() + lower.slice(1);
  };
  return (
    <div style={{ height: "50px" }}>
      {props.alert && (
        <div className="container">
          <div
            className={`alert alert-${props.alert.type} fade show my-3`}
            role="alert"
          >
            <strong>{capitalize(props.alert.type)}: </strong>
            {capitalize(props.alert.msg)}
            {/* <button type="button" className="btn btn-close" data-bs-dismiss="alert" aria-label="Close"></button> */}
          </div>
        </div>
      )}
    </div>
  );
}

export default Alert;
