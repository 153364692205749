import React, { useContext, useState, useEffect, useRef } from "react";
import NoteContext from "../context/notes/noteContext";
import NoteItem from "./NoteItem";
import AddNote from "./AddNote";
import { useNavigate } from "react-router-dom";

const Notes = (props) => {
  const { showAlert, mode } = props;
  const context = useContext(NoteContext);
  const { notes, getNotes, editNote } = context;
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("token")) {
      getNotes();
    } else {
      props.showAlert("You are not logged in", "danger");
      navigate("/login");
    }
  }, [getNotes, navigate, props]);
  // eslint-disable-next-line

  const ref = useRef(null);
  const refClose = useRef(null);

  const [note, setNote] = useState({
    id: "",
    etitle: "",
    edescription: "",
    etag: "",
  });

  const updateNote = (currentNote) => {
    ref.current.click();
    setNote({
      id: currentNote._id,
      etitle: currentNote.title,
      edescription: currentNote.description,
      etag: currentNote.tag,
    });
  };
  const handleSubmit = (e) => {
    // console.log("Updating the note", note);
    e.preventDefault();
    editNote(note.id, note.etitle, note.edescription, note.etag);
    refClose.current.click();
    showAlert("Note Updated Successfully", "success");
  };
  const onChange = (e) => {
    setNote({ ...note, [e.target.name]: e.target.value });
  };

  return (
    <div className="container">
      <div className="col-md-6">
        <AddNote showAlert={showAlert} mode={mode} />
      </div>
      <div className="col-md-12">
        <button
          type="button"
          ref={ref}
          className="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
          style={{ display: "none" }}
        >
          Edit Note
        </button>

        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {note.etitle}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <form className="needs-validation">
                  <div className="mb-3">
                    <label htmlFor="etitle" className="form-label">
                      Note Title
                    </label>
                    <input
                      type="text"
                      name="etitle"
                      className="form-control"
                      id="etitle"
                      value={note.etitle}
                      aria-describedby="etitleHelp"
                      required
                      onChange={onChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="edescription" className="form-label">
                      Description
                    </label>
                    <textarea
                      name="edescription"
                      className="form-control"
                      id="edescription"
                      rows={3}
                      minLength={5}
                      value={note.edescription}
                      required
                      onChange={onChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="etag" className="form-label">
                      Tag
                    </label>
                    <input
                      type="text"
                      name="etag"
                      className="form-control"
                      id="etag"
                      value={note.etag}
                      required
                      onChange={onChange}
                    />
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  ref={refClose}
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  disabled={
                    note.etitle.length < 5 ||
                    note.edescription.length < 5 ||
                    note.etag.length < 3
                  }
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSubmit}
                >
                  Update Note
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <h1
            className={`mt-4 text-${props.mode === "light" ? "dark" : "light"}`}
          >
            Notes
          </h1>
          <div className="row my-2">
            <div className="container text-danger">
              {notes.length === 0 && "No notes to display"}
            </div>
            {notes.map((note) => {
              return (
                <NoteItem
                  key={note.id}
                  updateNote={updateNote}
                  note={note}
                  showAlert={props.showAlert}
                  mode={props.mode}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notes;
