import React, { useContext, useState } from "react";
import noteContext from "../context/notes/noteContext";
// import Notes from './Notes';

const AddNote = (props) => {
  const context = useContext(noteContext);
  const { addNote } = context;

  const [note, setNote] = useState({
    title: "",
    description: "",
    tag: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    addNote(note.title, note.description, note.tag);
    setNote({
      title: "",
      description: "",
      tag: "",
    });
    props.showAlert("Note Added Successfully", "success");
  };

  const onChange = (e) => {
    setNote({ ...note, [e.target.name]: e.target.value });
  };
  return (
    <div
      className={`container text-${props.mode === "light" ? "black" : "light"}`}
    >
      <h1
        className={`text-${
          props.mode === "light" ? "black" : "light"
        }`}
      >
        Add a Note
      </h1>
      <form className="needs-validation">
        <div className="mb-3">
          <label htmlFor="title" className="form-label">
            Note Title
          </label>
          <input
            type="text"
            name="title"
            className="form-control"
            id="title"
            aria-describedby="titleHelp"
            required
            onChange={onChange}
            minLength={5}
            value={note.title}
            style={{backgroundColor: props.mode==='dark'?'#13466e':'white', color: props.mode==='dark'?'white':'#042743'}}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="description" className="form-label">
            Description
          </label>
          <textarea
            name="description"
            className="form-control"
            id="description"
            rows={3}
            required
            onChange={onChange}
            minLength={5}
            value={note.description}
            style={{backgroundColor: props.mode==='dark'?'#13466e':'white', color: props.mode==='dark'?'white':'#042743'}}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="tag" className="form-label">
            Tag
          </label>
          <input
            type="text"
            name="tag"
            className="form-control"
            id="tag"
            required
            onChange={onChange}
            minLength={3}
            value={note.tag}
            style={{backgroundColor: props.mode==='dark'?'#13466e':'white', color: props.mode==='dark'?'white':'#042743'}}
          />
        </div>
        <button
          type="submit"
          className="btn btn-primary"
          onClick={handleSubmit}
          disabled={
            note.title.length < 5 ||
            note.description.length < 5 ||
            note.tag.length < 3
          }
        >
          Add Note
        </button>
      </form>
    </div>
  );
};

export default AddNote;
