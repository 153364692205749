import React from 'react'

const Footer = (props) => {
    return (
      <footer className={`footer mt-auto py-3 bg-${props.mode}`}>
        <div className="container">
          <span className={`text-${props.mode==='light'?'dark':'light'}`}>&copy; 2023 Echo Notebook. All Rights Reserved.</span>
        </div>
      </footer>
    );
}

export default Footer;