import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const SignUp = (props) => {
  let navigate = useNavigate();
  const [credentials, setCredentials] = useState({
    name: "",
    email: "",
    password: "",
    confirmpassword: "",
  });

  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.id]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, email, password } = credentials;
    //API call
    const response = await fetch("https://echo-notebook.onrender.com/api/auth/createuser", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({ name, email, password }),
    });
    const json = await response.json();

    // Save the token and redirect
    if (json.success) {
      // Save the auth token and redirect
    //   localStorage.setItem("token", json.authToken);
      props.showAlert("Account created successfully", "success");
      navigate("/login");
    } else {
      props.showAlert("Invalid Credentials", "danger");
      navigate("/signup");
      setCredentials({
        name: "",
        email: "",
        password: "",
        confirmpassword: "",
      });
    }
  };

  return (
    <div
      className={`container body-container bg-${
        props.mode === "light" && "transparent"
      }`}
    >
      <div className={`text-${props.mode === "light" ? "dark" : "light"}`}>
        <h1>Sign Up</h1>
        <form onSubmit={handleSubmit}>
          <div className="form-group mb-4">
            <label htmlFor="email">Name</label>
            <input
              type="text"
              className="form-control"
              id="name"
              name="name"
              value={credentials.name}
              onChange={onChange}
              placeholder="Enter name"
              required
            />
          </div>
          <div className="form-group mb-4">
            <label htmlFor="email">Email address</label>
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              value={credentials.email}
              onChange={onChange}
              placeholder="Enter email"
              required
            />
          </div>
          <div className="form-group mb-4">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              className="form-control"
              id="password"
              name="password"
              value={credentials.password}
              onChange={onChange}
              placeholder="Password"
              minLength={5}
              required
            />
          </div>
          <div className="form-group mb-4">
            <label htmlFor="confirmpassword">Confirm Password</label>
            <input
              type="password"
              className="form-control"
              id="confirmpassword"
              name="confirmpassword"
              value={credentials.confirmpassword}
              onChange={onChange}
              placeholder="Password"
              minLength={5}
              required
            />
          </div>
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default SignUp;
