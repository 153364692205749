import React, { useContext } from "react";
import noteContext from "../context/notes/noteContext";

const NoteItem = (props) => {
  const { showAlert } = props;
  const context = useContext(noteContext);
  const { deleteNote } = context;
  const { note, updateNote } = props;
  return (
    <div className="col-lg-4">
      <div className={`card my-3 text-${props.mode === "light" ? "dark" : "light"} shadow-sm p-3 mb-5 rounded`} style={{backgroundColor: props.mode==='dark'?'#13466e':'white', color: props.mode==='dark'?'white':'#042743', border: props.mode==='dark'?'1px solid #ffffff':'' }}>
        <div className="card-body">
          <span className="mt-2 badge rounded-pill bg-info text-dark mb-4">
            Tag: {note.tag}
          </span>
          <div className="d-flex justify-content-between">
            <h5 className="card-title">{note.title}</h5>
            <span>
              <i
                className="far fa-edit mx-2"
                onClick={() => {
                  updateNote(note);
                }}
              ></i>
              <i
                className="far fa-trash-alt mx-2"
                onClick={() => {
                  deleteNote(note._id);
                  showAlert("Note Deleted Successfully", "success");
                }}
              ></i>
            </span>
          </div>
          <p className="card-text">
            {note.description}
            <br />
            <small>
              <i>
                <br />
                Added on: {note.date.slice(0, 16)}
              </i>
            </small>
          </p>
        </div>
      </div>
    </div>
  );
};

NoteItem.defaultProps = {
  note: {
    title: "Default Title",
    description: "Default Description",
  },
};

export default NoteItem;
