import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useLocation, useNavigate } from "react-router-dom";

export default function Navbar(props) {
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("token");
    props.showAlert("Logged out Successfully", "success");
    navigate("/login");
    // window.location.reload();
  };

  let location = useLocation();
  useEffect(() => {
    // document.title = `${props.title}`;
  }, [location]);

  return (
    <nav
      className={`navbar navbar-expand-lg navbar-${props.mode} bg-${props.mode}`}
    >
      <div className="container">
        <Link className="navbar-brand d-flex justify-content-center" to="/">
          <img
            src="apple-touch-icon.png"
            className="rounded"
            alt="Logo"
            height="50px"
          />
          <div
            className="mx-4 mt-2"
            style={{
              height: "50px",
              lineHeight: "0.75em",
              verticalAlign: "middle",
            }}
          >
            {props.title}
            <small>
              <small>
                <br />
                Your Notebook on the Cloud
              </small>
            </small>
          </div>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  location.pathname === "/" ? "active" : ""
                }`}
                to="/"
              >
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  location.pathname === "/about" ? "active" : ""
                }`}
                to="/about"
              >
                About
              </Link>
            </li>
          </ul>
          {!localStorage.getItem("token") ? (
            <form className="d-flex mx-3">
              <Link className="btn btn-primary" to="/login" role="button">
                Login
              </Link>
              <Link className="btn btn-success mx-2" to="/signup" role="button">
                Sign Up
              </Link>
            </form>
          ) : (
            <>
            <span className="mx-2">Howdy, User!</span>
            <button onClick={handleLogout} className="btn btn-primary">
              Logout
            </button>
            </>
          )}

          <div
            className={`mx-2 form-check form-switch text-${
              props.mode === "light" ? "dark" : "light"
            }`}
          >
            <input
              className="form-check-input"
              onClick={props.toggleMode}
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
            />
            <label
              className="form-check-label"
              htmlFor="flexSwitchCheckDefault"
            >
              Dark Mode
            </label>
          </div>
        </div>
      </div>
    </nav>
  );
}

Navbar.propTypes = { title: PropTypes.string.isRequired };
Navbar.defaultProps = { title: "Echo Notebook" };
