import "./App.css";
import Navbar from "./components/Navbar.js";
import ErrorPage from "./components/ErrorPage.js";
import About from "./components/About";
import Alert from "./components/Alert";
import React, { useState } from "react";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Footer from "./components/Footer";
import NoteState from "./context/notes/NoteState";
import Home from "./components/Home";
import SignUp from "./components/SignUp";
import Login from "./components/Login";

function App() {
  const [mode, setMode] = useState("light"); // whether dark mode is enabled or not
  const [alert, setAlert] = useState(null);

  // const title = "Echo Notes | Your Notes on the Cloud";

  const showAlert = (message, type) => {
    setAlert({
      msg: message,
      type: type,
    });
    setTimeout(() => {
      setAlert(null);
    }, 1500);
  };

  const toggleMode = () => {
    if (mode === "light") {
      setMode("dark");
      document.body.style.backgroundColor = "#042743";
      // showAlert("Dark mode has been enabled", "success");
      // document.title = "Dark Mode - " + title;
    } else {
      setMode("light");
      document.body.style.backgroundColor = "white";
      // showAlert("Light mode has been enabled", "success");
      // document.title = "Light Mode - " + title;
    }
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: [
        <NoteState>,
        <Navbar title="Echo" mode={mode} toggleMode={toggleMode} showAlert={showAlert} />,
        <Alert alert={alert} />,
        <Home mode={mode} showAlert={showAlert} />,
        <Footer mode={mode} />,
        </NoteState>,
      ],
      errorElement: <ErrorPage />,
    },
    {
      path: "/about",
      element: [
        <NoteState>,
        <Navbar title="Echo" mode={mode} toggleMode={toggleMode} />,
        <Alert alert={alert} />,
        <About mode={mode} showAlert={showAlert} />,
        <Footer mode={mode} />,
        </NoteState>,
      ],
    },
    {
      path: "/login",
      element: [
        <NoteState>,
        <Navbar title="Echo" mode={mode} toggleMode={toggleMode} />,
        <Alert alert={alert} />,
        <Login mode={mode} showAlert={showAlert} />,
        <Footer mode={mode} />,
        </NoteState>,
      ],
    },
    {
      path: "/signup",
      element: [
        <NoteState>,
        <Navbar title="Echo" mode={mode} toggleMode={toggleMode} />,
        <Alert alert={alert} />,
        <SignUp mode={mode} showAlert={showAlert} />,
        <Footer mode={mode} />,
        </NoteState>,
      ],
    },
  ]);

  return (
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
}

export default App;
